<template>
  <Member
    title="Prof. Alberto Zangrillo"
    description="Direttore dell’Unità Operativa di Anestesia e Rianimazione Generale e Cardio-Toraco-Vascolare"
    text="Il Professor Alberto Zangrillo è primario dell’Unità Operativa di Anestesia e Rianimazione Generale e Cardio-Toraco-Vascolare e Referente Direzionale Aree Cliniche dell’IRCCS Ospedale San Raffaele di Milano. Per l’Università Vita-Salute San Raffaele ricopre i ruoli di Prorettore per le attività cliniche e professore Ordinario di Anestesiologia e Rianimazione.

Il Professor Alberto Zangrillo è tra i primi dieci medici al mondo (nell’ultimo biennio) per numero di pubblicazioni in ambito “anesthesia” e “intensive care” (fonte: Scopus), autore di oltre 800 pubblicazioni, di cui oltre 360 su riviste internazionali indicizzate (citate più di 9000 volte, h-index 46) tra cui studi randomizzati su The New England Journal of Medicine, JAMA, Circulation e British Medical Journal.

La sua attività autoriale si completa inoltre di 38 titoli (tra monografie e capitoli di libri) e di incarichi di Editor-in-Chief della rivista Heart Lung and Vessels (indicizzata su PubMed), di membro dell’Editorial board di Advances in Medicine e quello di revisore di grant per donor internazionali (tra cui la Swiss National Science Foundation, nel periodo 2009-2015).

Vincitore di diversi bandi di ricerca Finalizzata del Ministero della Salute, di un bando AIFA Ricerca Indipendente sui farmaci nel 2012 e, nello stesso anno, di un bando Conto Capitale (nonché di altri bandi e progetti finanziati dalla Regione Lombardia), in qualità di Principal Investigator partecipa a 42 protocolli di ricerca randomizzati, approvati dal Comitato Etico dell’Ospedale San Raffaele.

Sempre in ambito di ricerca, tra le tante linee perseguite, le principali riguardano i supporti avanzati al circolo e alla ventilazione (ECMD, VAD, cuore artificiale), il trattamento dello scompenso cardiaco acuto, la ventilazione non invasiva (in terapia intensiva e ai piani di degenza), le terapie anticoagulanti alternative in terapia intensiva, l’ottimizzazione dell’emostasi perioperatoria, la cardioprotezione da alogenati, la prevenzione della mortalità perioperatoria e il trattamento dell’infarto miocardico acuto perioperatorio, la prevenzione e il trattamento dell’insufficienza renale acuta perioperatoria e del danno d’organo del paziente critico, la sepsi in terapia intensiva.

Oltre a premi e riconoscimenti in ambito medico e a incarichi istituzionali, è insignito del titolo di Cavaliere al merito della Repubblica Italiana e di Commendatore dai Presidenti della Repubblica Carlo Azeglio Ciampi e Giorgio Napolitano."
    :formation="[
      'Laurea in Medicina e Chirurgia, Università degli Studi di Milano,1983',
      'Laurea specialistica in Anestesia e Rianimazione, Università degli Studi di Milano, 1986',
      'Studi in centri europei: il Queen Charlotte Hospital di Londra, l’Hospital de la Santa Creu Pau do Barcellona, il Cardio-thoracic Centre di Monaco di Montecarlo, l’Hetzer Deutsches Herzzentrum di Berlino e l’Ospedale San Raffaele.',
      '',
    ]"
    publication=""
    :pub="false"
    :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Zangrillo",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
